import { quickFetchFrameworkDataSchema } from './frameworkDataSchema';
import { quickFetchAssociationDefinitions } from './associationDefinitions';

/**
 * Quick fetches crm-object-table framework data dependencies
 * as well as association definitions for the object type provided.
 *
 * @param {String} params.objectTypeId - The ID of the object type
 * to fetch data for.
 */
export async function crmObjectTableQuickFetch({
  objectTypeId
}) {
  const quickFetchPromises = [quickFetchFrameworkDataSchema({
    objectTypeId
  }), quickFetchAssociationDefinitions({
    objectTypeId
  })];
  await Promise.all(quickFetchPromises);
}