/*
 * WARNING: Do not add more imports into this file! It is used in a quick-fetch, and imports
 * will bloat the quick-fetch bundle, slowing down the app.
 */
import { maxNumberOfViewsToQuickFetch } from '../rewrite/pinnedViews/constants/PinnedViewLimit';
import { APP_SETTINGS_TO_FETCH } from '../frameworkAppSettings/constants/AppSettings';
import { getFrameworkDataQuickFetchClient } from 'framework-data-schema-quick-fetch';
import { quickFetchRecordCardProperties, quickFetchBoardFrameworkData } from 'crm-object-board/quickFetch';
import { crmObjectTableQuickFetch } from 'crm-object-table/quickFetch';
export const doQuickFetches = ({
  objectTypeId,
  viewId,
  pipelineId,
  makeRestRequest,
  makeGqlRequest,
  afterAuth,
  setQuickFetchErrorOnWindow,
  pageType
}) => {
  const encodedTypeId = encodeURIComponent(objectTypeId);
  const encodedViewId = encodeURIComponent(viewId);
  makeGqlRequest();
  if (objectTypeId) {
    makeRestRequest('pinnedViewDefinitions', `/sales/v4/views/${encodedTypeId}/pinned/view?count=${maxNumberOfViewsToQuickFetch}`);
    if (pageType === 'board') {
      quickFetchRecordCardProperties(objectTypeId, pipelineId);
      quickFetchBoardFrameworkData(objectTypeId).catch(err => {
        setQuickFetchErrorOnWindow(err);
      });
    } else if (pageType === 'list') {
      crmObjectTableQuickFetch({
        objectTypeId
      }).catch(err => {
        setQuickFetchErrorOnWindow(err);
      });
    }
    getFrameworkDataQuickFetchClient().then(client => {
      return Promise.all([client.properties.get({
        frameworkTypeIdentifier: objectTypeId,
        query: {
          showHighlySensitiveProperties: true
        }
      }), client.typeMetadata.get({
        appSettingNames: [...APP_SETTINGS_TO_FETCH, 'crmRecordsPage',
        //CRM LINKS
        'crmPageEditor' //CRM LINKS
        ]
      }), client.pipelines.get({
        frameworkTypeIdentifier: objectTypeId
      }), client.frameworkTypes.getObjects()]);
    }).catch(err => {
      setQuickFetchErrorOnWindow(err);
    });
  }
  if (viewId && objectTypeId) {
    makeRestRequest(`view-${viewId}`, `/sales/v4/views/${encodedTypeId}/${encodedViewId}`);
  }
  if (afterAuth) {
    afterAuth(authData => {
      const hasScope = scope => authData && authData.user && authData.user.scopes && authData.user.scopes.includes(scope);

      // const hasGate = gate =>
      //   authData &&
      //   authData.portal &&
      //   authData.portal.enabled_gates &&
      //   authData.portal.enabled_gates.includes(gate);

      if (encodedTypeId === '0-48' && hasScope('crm-transcription')) {
        /* This quickfetch has been added ad-hoc by ml-fe-ci team: https://private.hubteam.com/janus/groups/hs-ml-fe-ci
        It pre-loads the state of the transcription setting to show onboarding features on the call index page
        Pre-fetching this setting allows to render the quick filter on load of the page without flickers or disable state changes
        For any questions/concerns, please use Slack channel: #sales-leader-insights-fe */

        makeRestRequest('transcript-setting', '/transcript/v1/setting/transcript');
      }
    });
  }
};