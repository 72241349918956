import { getFrameworkDataQuickFetchClient } from 'framework-data-schema-quick-fetch';
import { APP_SETTINGS_TO_FETCH } from '../frameworkAppSettings/constants/AppSettings';
export async function quickFetchFrameworkDataSchema({
  objectTypeId
}) {
  const quickFetchClient = await getFrameworkDataQuickFetchClient();
  quickFetchClient.properties.get({
    frameworkTypeIdentifier: objectTypeId,
    query: {
      showHighlySensitiveProperties: true
    }
  });
  quickFetchClient.frameworkTypes.getObjects();
  quickFetchClient.typeMetadata.get({
    appSettingNames: APP_SETTINGS_TO_FETCH
  });
}